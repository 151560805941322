// Import core modules
import React, { useState, useEffect } from 'react';

// Import elements
import {
  Button,
  Content,
  Header,
  HeaderContainer,
  HeaderName,
} from 'carbon-components-react';

/**
 * Core function
 */
export default function NotAuthorized({ history }) {
  useEffect(() => {
  }, []);

  return (
    <>
    <div className="landing-bg">
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan EAST">
              <HeaderName prefix="Madigan">
                EAST
              </HeaderName>
            </Header>
          </>
        )}
      />
      <Content className="landing-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-4 landing-container">
              <div className="landing-vertical-center">
                <p>
                  This account is not authorized to log into EAST.
                </p>
                <Button
                  kind='ghost'
                  href='/'
                >
                  Return to homepage
                </Button>
              </div>
            </div>
            <div className="bx--col-4"></div>            
          </div>
        </div>
      </Content>
    </div>
    </>
  );
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LandingPage from '../pages/LandingPage';
import Users from '../pages/Users/Users';
import UserForm from '../pages/Users/UserForm';
import NotAuthorized from '../pages/LandingPage/NotAuthorized';

import ProtectedRoute from '../lib/ProtectedRoute';



export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/health"><p>Application is healthy</p></Route>
        <Route path="/notauthorized" component={NotAuthorized} ></Route>
        <ProtectedRoute path="/users/edit/:id" component={UserForm} />
        <ProtectedRoute path="/users" component={Users} />
      </Switch>
    </>
  );
}

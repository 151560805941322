// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  DataTable,
  DatePicker,
  DatePickerInput,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  Select,
  SelectItem,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListInput,
  StructuredListCell,
  Tag,
  TextArea,
  TextInput,
  StructuredListWrapper
} from 'carbon-components-react';

import { Add16, Search16, TrashCan16 } from '@carbon/icons-react';



// Setup variables
const apiuri = process.env.REACT_APP_APIURI;
const vTenantUrl = process.env.REACT_APP_VERIFY_TENANT_URL;
const uniqrUrl = process.env.REACT_APP_UNIQR_URI;
const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent
} = DataTable;

/**
 * Core function
 */
export default function User({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [rows, setRows] = useState([]);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [formErrors, setFormErrors] = useState({});

  // Individual schema attributes
  const [alias, setAlias] = useState('');
  const [currentAlias, setCurrentAlias] = useState('');
  const [userName, setUserName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [status, setStatus] = useState('');
  const [realm, setRealm] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const {
    handleSubmit
  } = useForm({});



  var formIsValid = true;
  var errorList = [];

  

  /**
   * Configure functions for handling form submissions
   */
  function onSubmit(data) {
    updateUser(alias);
  }



  /**
   * Update user
   */
  function updateUser(alias) {
    if (realm !== 'cloudIdentityRealm') {
      setModalMessage('This account cannot be updated - it is not within the Cloud Identity Realm');
      setModalType('danger');
      setModalOpen(true);
    } else {
      // Check uniqr
      const api = apiuri + '/fatlad/alias';

      const options = {
        method: 'POST',
        url: api,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: {
          uHeaders: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('uAccessToken'),
          },
          vHeaders: {
            Accept: 'application/scim+json',
            Authorization: 'Bearer ' + localStorage.getItem('vAccessToken'),
            'content-type': 'application/scim+json',
          },
          alias: alias,
          id: id,
        }
      };

      axios.request(options).then((response) => {
        if (response.data.status === 201) {
          setCurrentAlias(alias);
          var message = 'The alias for ' + userName + ' has been updated';
          setModalMessage(message);
          setModalType('primary');
          setModalOpen(true);
        } else if (response.data.status === 200) {
          setModalMessage('This alias is taken');
          setModalType('primary');
          setModalOpen(true);
        } else {
          setModalMessage('An error has occurred');
          setModalType('danger');
          setModalOpen(true);
        }
      }).catch((err) => {
        console.log('error: ' + err);
      });
    }
  }



  /**
   * 
   * Decode JWT
   */
  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }



  /**
   * Load data
   */
  function loadData() {
    // Get user by id
    var api = apiuri + '/fatlad/' + id;

    const options = {
      method: 'POST',
      url: api,
      headers: {
        Accept: 'application/scim+json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: {
        vHeaders: {
          Accept: 'application/scim+json',
          Authorization: 'Bearer ' + localStorage.getItem('vAccessToken'),
        }
      }
    };

    axios.request(options)
      .then((response) => {
        if (response.data.userDetails) {
          var details = response.data.userDetails;
          if (details.userName) { setUserName(details.userName); }
          if (details.name.givenName) { setGivenName(details.name.givenName); }
          if (details.name.familyName) { setFamilyName(details.name.familyName); }
          if (details.emails[0].value) { setEmailAddress(details.emails[0].value); }
          if (details.active) {
            var stat = '';
            if (details.active === true) {
              stat = 'Active';
            } else if (details.active === false) {
              stat = 'Inactive';
            }
            setStatus(stat);
          }
          if (details["urn:ietf:params:scim:schemas:extension:ibm:2.0:User"]["realm"]) {
            setRealm(details["urn:ietf:params:scim:schemas:extension:ibm:2.0:User"]["realm"]);
          }
          if (details["urn:ietf:params:scim:schemas:extension:ibm:2.0:User"]["customAttributes"]) {
            var customAttributes = details["urn:ietf:params:scim:schemas:extension:ibm:2.0:User"]["customAttributes"];
            customAttributes.forEach((el) => {
              if (el.name === 'lsbustaffemailalias') {
                setCurrentAlias(el.values[0]);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log('error: ' + err);
      });
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <h3 className="umt--heading">Assign email alias to <strong>{userName}</strong></h3>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <TextInput
                    value={alias}
                    id="alias"
                    type="text"
                    labelText="New alias"
                    onChange={(e) => setAlias(e.target.value)}
                  />
                </div>
                <ButtonSet>
                  <Button kind="secondary" onClick={() => ( history.goBack() )}>Cancel</Button>
                  <Button kind="primary" type="submit">Update alias</Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-8">
              <h3 className="umt--heading">User details:</h3>
              <StructuredListWrapper>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>ID</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {id}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Alias</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {currentAlias}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Username</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {userName}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Given name</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {givenName}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Family name</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {familyName}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Email</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {emailAddress}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Realm</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {realm}
                  </StructuredListCell>
                </StructuredListRow>
                <StructuredListRow>
                  <StructuredListCell>
                    <strong>Status</strong>
                  </StructuredListCell>
                  <StructuredListCell>
                    {status}
                  </StructuredListCell>
                </StructuredListRow>
              </StructuredListWrapper>
            </div>
          </div>
        </div>
      </Content>

      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">User update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}
// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Button,
  ComposedModal,
  Content,
  DataTable,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tile,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;
const headers = [
  { key: 'id', header: 'ID' },
  { key: 'username', header: 'Username' },
  { key: 'givenName', header: 'Given name' },
  { key: 'familyName', header: 'Family name' },
  { key: 'email', header: 'Email' },
  { key: 'status', header: 'Status' }
];



const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions,
} = DataTable;



/**
 * Core function
 */
export default function UsersPage({ history, match }) {
  const { id } = match.params;

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  
  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [start, setStart] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();
  const [modalHeading, setModalHeading] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);



  function getUsers(filter) {
    var api = apiuri + '/fatlad/findByName?filter=' + filter;

    const options = {
      method: 'POST',
      url: api,
      headers: {
        Accept: 'application/scim+json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: {
        vHeaders: {
          Accept: 'application/scim+json',
          Authorization: 'Bearer ' + localStorage.getItem('vAccessToken'),
        }
      }
    };

    axios
      .request(options)
      .then((response) => {
        var payload = [];
        if (response.data.Resources.length !== 0) {;
          response.data.Resources.forEach((element) => {
            var newElement = {};
            if (element.id){
              newElement.id = element.id;
            }
            if (element.userName) {
              newElement.username = element.userName;
            }
            if (element.name) {
              if (element.name.givenName) {
                newElement.givenName = element.name.givenName;
              }
              if (element.name.familyName) {
                newElement.familyName = element.name.familyName;
              }
            }
            if (element.emails) {
              newElement.email = element.emails[0].value;
            }
            newElement.status = '';
            if (element.active === true) {
              newElement.status = 'Active';
              newElement.username = <Link href={'/users/edit/' + newElement.id}>{newElement.username}</Link>;
            } else if (element.active === false) {
              newElement.status = 'Inactive';
            }
            payload.push(newElement);
          });
          setTotalItems(response.data.totalResults)
        } else {
          setModalMessage('No users found');
          setModalType('default');
          setModalHeading('Info');
          setModalOpen(true);
          setTotalItems(0);
        }
        setRows(payload)
        setAllRows(payload);
      })
      .catch((err) => {
        console.log('error finding user: ' + err);
        setModalMessage('An error has occurred');
        setModalType('danger');
        setModalHeading('Error');
        setModalOpen(true);
      });
  }



  function getNumberOfUsers() {
    var api = apiuri + '/fatlad/count';
    const options = {
      method: 'POST',
      url: api,
      headers: {
        Accept: 'application/scim+json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: {
        vHeaders: {
          Accept: 'application/scim+json',
          Authorization: 'Bearer ' + localStorage.getItem('vAccessToken'),
        }
      }
    };
    
    axios.request(options).then((response) => {
        setTotalUsers(response.data.totalUsers);
      })
      .catch(function (error) {
        console.error(error);
      });
  }



  /**
   * Initialise
   */
  useEffect(() => {
    getNumberOfUsers();
  }, []);




  return (
    <>
      <UMTHeader />
      <Content id="main-content">
        <h1 className="umt--heading">Search</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <Tile>
                <h3 className="umt--tile-header">Users</h3>
                <p className="umt--tile-value">{totalUsers}</p>
              </Tile>
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
                <DataTable
                  rows={rows.slice(
                    firstRowIndex,
                    firstRowIndex + currentPageSize
                  )}
                  headers={headers}
                  render={({
                    rows,
                    headers,
                    getHeaderProps,
                    getTableProps,
                    getSelectionProps,
                    getToolbarProps,
                    getBatchActionProps,
                    onInputChange,
                    selectedRows,
                    getTableContainerProps,
                  }) => (
                    <TableContainer className="umt--table-container">
                      <TableToolbar {...getToolbarProps()}>
                        <TableBatchActions {...getBatchActionProps()}>
                          <TableBatchAction>
                            Change
                          </TableBatchAction>
                        </TableBatchActions>
                        <TableToolbarContent>
                          <TableToolbarSearch
                            defaultExpanded
                            onChange={(e) => {
                              setSearchFilter(e.target.value.replace(/[^\w\s]/gi, ''));
                            }}
                          />
                          <Button
                            size="small"
                            kind="primary"
                            onClick={(e) => {
                              getUsers(searchFilter);
                            }}
                          >
                            Search
                          </Button>
                        </TableToolbarContent>
                      </TableToolbar>

                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            {headers.map((header) => (
                              <TableHeader {...getHeaderProps({ header })}>
                                {header.header}
                              </TableHeader>
                            ))}
                            <TableHeader className="umt--table-overflow" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.id}>
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>{cell.value}</TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        totalItems={totalItems}
                        backwardText="Previous page"
                        forwardText="Next page"
                        page={Math.floor(start / currentPageSize) + 1}
                        pageSize={currentPageSize}
                        pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                        itemsPerPageText="Items per page"
                        onChange={({ page, pageSize }) => {
                          if (totalItems === 0 || start < totalItems) {
                            // we should be grand
                          } else {
                            setStart(Math.max(start - (Math.floor((page - totalItems / pageSize) + 1) * pageSize), 0));
                          }
                          if (pageSize !== currentPageSize) {
                            setCurrentPageSize(pageSize);
                          }
                          setFirstRowIndex(pageSize * (page - 1));
                        }}
                      />
                    </TableContainer>
                  )}
                />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">{modalHeading}</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React from 'react';

// Import libraries
import { useAppContext } from '../../lib/context';

// Import components
import UMTHeaderLoggedIn from './HeaderLoggedIn';

/**
 * Core function
 */
const UMTHeader = () => {
  const { isAuthenticated } = useAppContext();
  return (
    <>
      <UMTHeaderLoggedIn />
    </>
  );
};

export default UMTHeader;
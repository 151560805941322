import React, { useState } from 'react';
import './App.scss';
import { AppContext } from './lib/context';

import Routes from './routes';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(localStorage.getItem('isAuthenticated') || false);

  return (
    <>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes />
      </AppContext.Provider>
    </>
  );
}

export default App;
